<template>
  <article class="partner-page">
    <h1 class="sr-only">Electrify America Harley-Davidson Page</h1>
    <Hero
      heading="Power up your LiveWire™ with Electrify America"
      headlineLevel="h2"
      subheading="With about 2,000 miles of complimentary charging, the road is yours."
    >
      <template slot="image">
        <img
          alt="A Harley Davidson Livewire motorcycle parked in front of two EA chargers."
          :src="require('@/assets/images/HarleyDavidson/plug image.png')"
        />
      </template>
    </Hero>
    <TwoColumnContentBlock heading="Power your ride" headlineLevel="h3">
      <p>
        At Electrify America, we give electric vehicle (EV) drivers the power to go farther. As a leader of EV charging,
        we’re building one of the largest networks of public fast charging stations that connect dense city streets with
        major highways running coast to coast. That means Harley-Davidson® LiveWire™ owners can experience electric
        riding the way it’s supposed to be—without limits.
      </p>
      <p>
        We’re collaborating with Harley-Davidson® so that you can experience the best in fast charging. Now, new
        LiveWire™ owners can get 500 kWh in complimentary charging over two years after purchase. That’s about 2,000
        <span class="wrap-last-two-words">
          miles
          <reference-link num="1"
            >Available on new Model Year 2019, 2020, and 2021 Harley-Davidson Livewire motorcycles purchased from an
            authorized Harley-Davidson dealer on or before October 2, 2021. Offer commences upon vehicle purchase and
            includes 500 kWh of charging over 2 years. Non-transferable. Not available for commercial use. Requires
            Electrify America account, app, acceptance of Terms of Use and Privacy Policy, and enrollment in the
            Harley-Davidson Charging plan. After user-initiated charge session stop of vehicle reaches full charge or
            charging otherwise stops, and 10 minute grace period, Idle Fees will be applied and user is responsible. In
            the event of suspected fraud, abuse, or other unforeseen events, Electrify America may discontinue or modify
            the offer in its sole discretion.
          </reference-link>
        </span>
        . Try it out and see how easy charging can be.
      </p>
      <router-link :to="{ name: 'locate-charger-en' }" class="button">LOCATE A CHARGER</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Charge from your phone" headlineLevel="h3">
      <template #image>
        <img
          loading="lazy"
          alt="A person using a mobile phone to start a charge for their Harley Davidson Livewire motorcycle. An EA
      connector is plugged into the motorcycle."
          :src="require('@/assets/images/HarleyDavidson/harleyimage2.png')"
        />
      </template>
      <p>
        To get your 500 kWh of complimentary charging, download the Electrify America app. You’ll be able to see all of
        the Electrify America chargers in your area and which ones are currently available. You can also track the
        progress of your charging sessions and get notifications when your battery is full. The LiveWire™ can charge at
        speeds up to 25 kW, so you can charge your motorcycle up to 80% in as little as 40 minutes. With the Electrify
        America app, charging has never been easier.
      </p>
      <p>To charge using your 500 kWh of complimentary Electrify America fast charging, follow these steps:</p>
      <ul>
        <li>
          <strong>Step 1:</strong> Download the Electrify America
          <span class="wrap-last-two-words"
            >app
            <reference-link num="1"
              >Your mobile carrier’s normal messaging, data, and other rates and fees will apply to your use of the
              Electrify America mobile application.</reference-link
            ></span
          >

          , create your account and select “Set Up Public Charging.”
        </li>
        <li>
          <strong>Step 2:</strong> Select “Choose Membership Plan” then “Premium Offers.”
          <ul>
            <li>Or, search for Premium Offer by vehicle information.</li>
            <li>
              Existing Electrify America members may select “Premium Offers” in the Account tab, then “I have an
              enrollment code.”
            </li>
          </ul>
        </li>
        <li>
          <strong>Step 3:</strong> Input your enrollment code, which you will receive from your Harley-Davidson dealer,
          and select the Harley-Davidson plan. Input your vehicle 17-digit
          <span class="wrap-last-two-words"
            >VIN
            <reference-link num="2"
              >The VIN can usually be found on the driver's side dashboard, near or under the bottom portion of the
              windshield.</reference-link
            ></span
          >

          for verification.
        </li>
        <li><strong>Step 4:</strong> Accept Plan Disclosure, then tap "Select this plan."</li>
        <li>
          <strong>Step 5:</strong> Enter payment
          <span class="wrap-last-two-words"
            >info
            <reference-link num="3"
              >To help ensure you can charge uninterrupted, your Electrify America account balance automatically reloads
              when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the first time you
              charge at an Electrify America station, and the billed amount will be held in your account to cover costs
              not covered by this plan, such as Idle Fees and applicable taxes.</reference-link
            ></span
          >
          and set up auto-reload.
          <ul>
            <li>
              You will need to enter a payment
              <span class="wrap-last-two-words"
                >method
                <reference-link num="3"
                  >To help ensure you can charge uninterrupted, your Electrify America account balance automatically
                  reloads when it falls below $5. You’ll be billed the auto-reload amount you select in the app, the
                  first time you charge at an Electrify America station, and the billed amount will be held in your
                  account to cover costs not covered by this plan, such as Idle Fees and applicable
                  taxes.</reference-link
                ></span
              >
              to cover any incurred Idle
              <span class="wrap-last-two-words"
                >Fees
                <reference-link num="4"
                  >Drivers who do not unplug and move their vehicle ten minutes after their charging session is complete
                  will be charged a $0.40 per-minute idle fee. This fee encourages drivers to move their car promptly so
                  that others can use the charger. You can be notified when your charging session is complete by text
                  message or see it on the charger screen.</reference-link
                ></span
              >
              or applicable taxes.
            </li>
          </ul>
        </li>
      </ul>
      <router-link :to="{ name: 'mobile-app-en' }" class="button">DOWNLOAD THE APP</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Go the distance" headlineLevel="h3">
      <template #image>
        <img
          loading="lazy"
          alt="A person plugging an EA connector into a Harley Davidson Livewire motorcycle. There is an EA charger in
      the background."
          :src="require('@/assets/images/HarleyDavidson/harleyimage3.png')"
        />
      </template>
      <p>
        With our vast network of Electrify America fast charging stations, you can go farther than ever with your
        LiveWire™. By the end of 2021, Electrify America expects to have at least 800 fast charging sites open
        nationwide. Some routes on the East Coast and in California will have stations less than 50 miles apart. That
        means you can experience the joy of riding your LiveWire™ in the city, over the mountains, or along the
        beach—and just about anywhere in between. With Electrify America, the road is calling. Where will you go?
      </p>
      <router-link :to="{ name: 'locate-charger-en' }" class="button">LOCATE A CHARGER</router-link>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';
import ReferenceLink from '@/components/ReferenceLink/ReferenceLink.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'harley-davidson',
  metaInfo: {
    title: 'Harley Davidson | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/harley-davidson/' }],
  },
  components: { ReferenceLink, Hero, TwoColumnContentBlock },
};
</script>
